import React, { useState } from 'react';
import './booking.css';
import { Form, FormGroup, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { useForm, ValidationError } from '@formspree/react';
import { useNavigate } from 'react-router-dom';

const Booking = ({ tour, avgRating }) => {
    const { price, reviews } = tour;
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        fullName: '',
        phone: '',
        guestSize: 1,
        bookAt: '',
        userEmail: ''
    });

    const [state, handleSubmit] = useForm('mrberyvr'); // Your Formspree form ID

    const handleChange = (e) => {
        setCredentials(prev => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const handleClick = async (e) => {
        e.preventDefault();
        if (state.submitting) return;

        // Ensure all fields are filled before submitting
        if (!credentials.fullName || !credentials.phone || !credentials.bookAt || !credentials.guestSize) {
            alert('Please fill in all fields before submitting.');
            return;
        }

        await handleSubmit({
            fullName: credentials.fullName,
            phone: credentials.phone,
            guestSize: credentials.guestSize,
            bookAt: credentials.bookAt,
            userEmail: credentials.userEmail
        });

        if (!state.errors) {
            navigate('/thank-you');
        }
    };

    const ServiceFee = 10;
    const totalAmount = Number(price) * Number(credentials.guestSize) + ServiceFee;

    return (
        <div className='booking'>
            <div className='booking__top d-flex align-items-center justify-content-between'>
                <h3>${price} <span>/Per Day</span></h3>
                <span className='tour__rating d-flex align-items-center '>
                    <i className='ri-star-fill'></i>
                    {avgRating === 0 ? null : avgRating}({reviews?.length})
                </span>
            </div>

            <div className='booking__form'>
                <h5>Information</h5>
                <Form className='booking__info-form' onSubmit={handleClick}>
                    <FormGroup>
                        <input 
                            type='text' 
                            placeholder='Full Name' 
                            id='fullName' 
                            required 
                            value={credentials.fullName} 
                            onChange={handleChange} 
                        />
                        <ValidationError 
                            prefix="Full Name" 
                            field="fullName" 
                            errors={state.errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <input 
                            type='number' 
                            placeholder='Phone' 
                            id='phone' 
                            required 
                            value={credentials.phone} 
                            onChange={handleChange} 
                        />
                        <ValidationError 
                            prefix="Phone" 
                            field="phone" 
                            errors={state.errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <input 
                            type='email' 
                            placeholder='Email' 
                            id='userEmail' 
                            required 
                            value={credentials.userEmail} 
                            onChange={handleChange} 
                        />
                        <ValidationError 
                            prefix="Email" 
                            field="userEmail" 
                            errors={state.errors}
                        />
                    </FormGroup>
                    <FormGroup className='d-flex align-items-center gap-3'>
                        <input 
                            type='date' 
                            id='bookAt' 
                            required 
                            value={credentials.bookAt} 
                            onChange={handleChange} 
                        />
                        <input 
                            type='number' 
                            placeholder='Guest' 
                            id='guestSize' 
                            required 
                            min='1' 
                            value={credentials.guestSize} 
                            onChange={handleChange} 
                        />
                    </FormGroup>
                    <Button 
                        className='btn primary__btn w-100 mt-4' 
                        type='submit' 
                        disabled={state.submitting}
                    >
                        {state.submitting ? "Booking..." : "Book Now"}
                    </Button>
                </Form>
            </div>

            <div className='booking__bottom'>
                <ListGroup>
                    <ListGroupItem className='border-0 px-0'>
                        <h5 className='d-flex align-items-center gap-1'>
                            ${price}<i className='ri-close-line'></i> 1 day
                        </h5>
                        <span>${price}</span>
                    </ListGroupItem>
                    <ListGroupItem className='border-0 px-0'>
                        <h5>Service Charge</h5>
                        <span>${ServiceFee}</span>
                    </ListGroupItem>
                    <ListGroupItem className='border-0 px-0 total'>
                        <h5>Total</h5>
                        <span>${totalAmount}</span>
                    </ListGroupItem>
                </ListGroup>
            </div>
        </div>
    );
};

export default Booking;
